import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-10 p-lg-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overview = _resolveComponent("Overview")
  const _component_ImageUsage = _resolveComponent("ImageUsage")
  const _component_InlineUsage = _resolveComponent("InlineUsage")
  const _component_IconColors = _resolveComponent("IconColors")
  const _component_IconSizes = _resolveComponent("IconSizes")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Overview),
      _createVNode(_component_ImageUsage),
      _createVNode(_component_InlineUsage),
      _createVNode(_component_IconColors),
      _createVNode(_component_IconSizes)
    ])
  ]))
}