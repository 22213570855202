
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Overview from "@/views/resources/documentation/general/duotone/Overview.vue";
import ImageUsage from "@/views/resources/documentation/general/duotone/ImageUsage.vue";
import InlineUsage from "@/views/resources/documentation/general/duotone/InlineUsage.vue";
import IconColors from "@/views/resources/documentation/general/duotone/IconColors.vue";
import IconSizes from "@/views/resources/documentation/general/bootstrap-icons/IconSizes.vue";

export default defineComponent({
  name: "duotone",
  components: {
    Overview,
    ImageUsage,
    InlineUsage,
    IconColors,
    IconSizes
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Duotone");
    });
  }
});
